import React, { useRef, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import Sidebar from "../components/Sidebar";
import Swal from "sweetalert2";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export default function Profile() {
  const user = JSON.parse(localStorage.getItem("user")).details;

  const edit = useRef();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [gender, setGender] = useState(user.gender);
  const [phone, setPhone] = useState(user.phone);
  const [program, setProgram] = useState(user.program);
  const [level, setLevel] = useState(user.level);

  const previewFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
    };
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    previewFile(file);
  };

  const handleProfilePic = async () => {
    setLoading(true);
    try {
      await axios
        .post(("https://molaapi.onrender.com/users/upload", { image: image }))
        .then((res) => {
          if (res.data.status === "ok") {
            axios
              .put(`https://molaapi.onrender.com/users/${user._id}`, {
                imageId: res.data.data.imageId,
                imageUrl: res.data.data.imageUrl,
              })
              .then((res) => {
                if (res.data.status === "ok") {
                  setLoading(false);
                  Swal.fire(
                    "Profile Picture",
                    "Profile Picture Updated Sucessfully",
                    "success"
                  );
                }
              });
          } else {
            setLoading(false);
            Swal.fire(
              "Profile Picture",
              "Error Uploading Profile Picture! \n Please try again!!",
              "error"
            );
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Profile Picture",
        "Error Uploading Profile Picture! \n Please try again!!",
        "error"
      );
    }
  };

  const handleUserUpdate = async () => {
    setLoading(true);
    try {
      await axios
        .put(
          (`https://molaapi.onrender.com/users/${user._id}`,
          {
            name: name,
            email: email,
            gender: gender,
            phone: phone,
            program: program,
            level: level,
          })
        )
        .then((res) => {
          setLoading(false);
          if (res.data.status === "ok") {
            Swal.fire("Profile", "User Profile Updated Sucessfully", "success");
          } else {
            Swal.fire(
              "Profile",
              "Error Updating User Profile! \n Please try again!!",
              "error"
            );
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Profile",
        "Error Updating User Profile! \n Please try again!!",
        "error"
      );
    }
  };

  return (
    <>
      <head>
        <title>Profile</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[50%] sm:mt-0 w-[100vw] h-[93vh] p-[20px] flex flex-col gap-[10px] justify-center items-center  overflow-auto no-scrollbar">
        <div className="relative flex flex-col mb-[30px] gap-[10px] justify-center items-center">
          {image === null || image === "" ? (
            <img
              src={user.imageUrl}
              alt="Profile Image"
              className="w-[100px] h-[100px] rounded-full"
            />
          ) : (
            <img
              src={image}
              alt="Profile Image"
              className="w-[100px] h-[100px] rounded-full"
            />
          )}
          <div className="flex gap-[10px] cursor-pointer">
            <div
              onClick={(e) => edit.current.click()}
              className="absolute top-[70px] right-0 bg-white p-[10px] rounded-full"
            >
              <FiEdit2 color="green" />
            </div>
            <input
              onClick={handleImage}
              ref={edit}
              type="file"
              className="hidden"
            />
          </div>
          <button
            onClick={handleProfilePic}
            className="text-black px-6 py-2 hover:bg-[#3bb143aa] bg-[#448844] text-white border-2 rounded-md shadow-md"
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Save Pic"
            )}
          </button>
        </div>
        <input
          onChange={(e) => {
            setName(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.name}
        />
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.email}
        />
        <input
          onChange={(e) => {
            setGender(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.gender}
        />
        <input
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.phone}
        />
        <input
          onChange={(e) => {
            setProgram(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.program}
        />
        <input
          onChange={(e) => {
            setLevel(e.target.value);
          }}
          type="text"
          name=""
          className="sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
          defaultValue={user.level}
        />
        <button
          onClick={handleUserUpdate}
          className="text-black px-6 py-2 hover:bg-[#3bb143aa] bg-[#448844] text-white border-2 rounded-md  shadow-md"
        >
          {loading ? (
            <CircularProgress size={20} className="text-white" />
          ) : (
            "Save Profile"
          )}
        </button>
      </div>
      <Sidebar />
    </>
  );
}
