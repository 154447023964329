import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import BackButton from "../components/back_button";

export default function Notifications() {
  const user = JSON.parse(localStorage.getItem("user")).details;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const getRooms = async () => {
    setLoading(true);
    await axios
      .post("https://molaapi.onrender.com/accepted-bookings/email", {
        email: user.email,
      })
      .then((value) => {
        setLoading(false);
        setData(value.data);
      });
  };
  useEffect(() => {
    getRooms();
    handleSearch(searchInput);
  }, [filteredResults, data]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return (
          item.title.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.body.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };
  return (
    <>
      <head>
        <title>Notifications</title>
      </head>
      <div className="sm:mt-0 w-[100vw] h-[100vh] text-black p-[10px] flex flex-col gap-[10px] overflow-auto no-scrollbar">
        <div className="relative mb-[10px] mt-[50px]">
          <FiSearch color="green" className="absolute top-[14px] ml-[10px]" />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            type="search"
            name=""
            className="pl-[30px] sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
            placeholder="Search for message"
          />
        </div>
        <BackButton />
        <div className="h-[70vh] grid sm:grid-cols-2 grid-cols-1 gap-[10px] pb-[30px] overflow-auto no-scrollbar">
          {filteredResults.length < 1 ? (
            <div className="w-full h-full flex justify-center items-center text-white">
              No Messages
            </div>
          ) : (
            filteredResults.map((items) => (
              <div className="h-fit flex flex-col gap-[10px] bg-white rounded-[5px] shadow-md p-[10px] hover:bg-[#00000033] hover:text-white">
                <p className="font-bold text-[#113311] hover:text-white">
                  {items.title}{" "}
                </p>
                <p>{items.body}</p>
              </div>
            ))
          )}
        </div>
      </div>
      <Sidebar />
    </>
  );
}
