import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { CircularProgress } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [id, setId] = useState("");
  const [Cpassword, setCPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [openOTP, setOpenOTP] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const menuHandler = () => {
    setOpenOTP((p) => !p);
  };
  const menuHandler2 = () => {
    setOpenPassword((p) => !p);
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios
        .post("https://molaapi.onrender.com/users/find", { email: email })
        .then((res) => {
          if (res.data.status === "ok") {
            setLoading(false);
            setId(res.data._id);
            axios
              .post("https://molaapi.onrender.com/confirmation", {
                email: email,
              })
              .then((res) => {
                if (res.data.status === "ok") {
                  setLoading(false);
                  Swal.fire("Reset Password", res.data.msg, "success");
                  menuHandler();
                } else {
                  setLoading(false);
                  Swal.fire("Error", res.data.msg, "error");
                }
              });
          } else {
            setLoading(false);
            Swal.fire("Reset Password", res.data.msg, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Error",
        "Something went wrong, please check your network!!",
        "error"
      );
    }
  };

  const handleEmailVerifcation = async () => {
    setLoading(true);
    try {
      await axios
        .get(`https://molaapi.onrender.com/confirmation/${email}/${token}`)
        .then((res) => {
          if (res.data.status === "ok") {
            setLoading(false);
            Swal.fire("Email Verfication", res.data.msg, "success");
            menuHandler2();
          } else {
            setLoading(false);
            Swal.fire(
              "Email Verfication",
              "Error Sending Token, Please try again!!",
              "error"
            );
            menuHandler();
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Email Verfication",
        "Error Verifying Email, Please try again!!",
        "error"
      );
      menuHandler();
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    if (password !== Cpassword) {
      setLoading(false);
      Swal.fire("Reset Password", "Passwords don't match!!", "error");
    } else {
      try {
        await axios
          .post("https://molaapi.onrender.com/users/encrypt", {
            password: password,
          })
          .then((res) => {
            if (res.data.status === "ok") {
              axios
                .put(`https://molaapi.onrender.com/users/${id}`, {
                  password: password,
                })
                .then((res) => {
                  if (res.data.status === "ok") {
                    setLoading(false);
                    menuHandler2();
                    Swal.fire(
                      "Reset Password",
                      "Password Changed Successfully, Please Login to access your account!!",
                      "success"
                    );
                    navigate("/");
                  } else {
                    setLoading(false);
                    Swal.fire("Error", "Error changing password!!", "error");
                    menuHandler2();
                  }
                });
            } else {
              setLoading(false);
              Swal.fire(
                "Email Verfication",
                "Error Sending Token, Please try again!!",
                "error"
              );
              menuHandler2();
            }
          });
      } catch (error) {
        setLoading(false);
        Swal.fire(
          "Email Verfication",
          "Error Verifying Email, Please check your network connection and try again!!",
          "error"
        );
        menuHandler2();
      }
    }
  };

  return (
    <div className="m-auto h-[100vh] w-[100vw] flex justify-center items-center bg-[#448844]">
      <head>
        <title>Mola App - Reset Password Page</title>
      </head>
      <div className="sm:w-[50vw] w-[80vw] h-[full] bg-white rounded-md p-[20px]">
        <Link to="/" className="w-full flex flex-row-reverse">
          <IoCloseCircle color="#448844" size={30} className="mb-[20px]" />
        </Link>
        <form className=" w-[100%] flex flex-col justify-center items-center">
          <label className="font-bold sm:text-xl uppercase text-[#448844]">
            Reset Password
          </label>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Enter Your Email</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <button
            onClick={handleEmail}
            disabled={loading}
            className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Request Token"
            )}
          </button>
        </form>
        {openOTP && (
          <div
            className="absolute w-full h-full text-white  flex justify-center items-center"
            onClose={menuHandler}
          >
            <div className="bg-white rounded-[20px] p-[10px] flex flex-col justify-center items-center">
              <div className="w-full flex flex-row-reverse">
                <IoCloseCircle
                  color="#448844"
                  size={30}
                  onClick={menuHandler}
                  className="mb-[20px]"
                />
              </div>
              <p className="text-black uppercase">
                Please enter <b>OTP</b>
              </p>
              <div className="w-full p-4 flex flex-col">
                <label className="font-bold sm:text-xl">Enetre OTP</label>
                <input
                  type="text"
                  className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                  onChange={(e) => {
                    setToken(e.target.value);
                  }}
                />
              </div>
              <button
                onClick={handleEmailVerifcation}
                disabled={loading}
                className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
              >
                {loading ? (
                  <CircularProgress size={20} className="text-white" />
                ) : (
                  "Verify"
                )}
              </button>
            </div>
          </div>
        )}
        {openPassword && (
          <div
            className="absolute w-full h-full text-white  flex justify-center items-center"
            onClose={menuHandler2}
          >
            <div className="bg-white rounded-[20px] p-[10px] flex flex-col justify-center items-center">
              <div className="w-full flex flex-row-reverse">
                <IoCloseCircle
                  color="#448844"
                  size={30}
                  onClick={menuHandler2}
                  className="mb-[20px]"
                />
              </div>
              <p className="text-black uppercase">Enter your new password</p>
              <div className="w-full p-4 flex flex-col">
                <label className="font-bold sm:text-xl">Password</label>
                <div className="relative ">
                  <input
                    type={passwordType}
                    className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {passwordType === "password" ? (
                    <HiEye
                      color="#3bb143"
                      className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                      onClick={togglePassword}
                    />
                  ) : (
                    <HiEyeOff
                      color="#3bb143"
                      className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                      onClick={togglePassword}
                    />
                  )}
                </div>
              </div>
              <div className="w-full p-4 flex flex-col">
                <label className="font-bold sm:text-xl">Confirm Password</label>
                <div className="relative ">
                  <input
                    type={passwordType2}
                    className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                    onChange={(e) => {
                      setCPassword(e.target.value);
                    }}
                  />
                  {passwordType2 === "password" ? (
                    <HiEye
                      color="#3bb143"
                      className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                      onClick={togglePassword2}
                    />
                  ) : (
                    <HiEyeOff
                      color="#3bb143"
                      className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                      onClick={togglePassword2}
                    />
                  )}
                </div>
              </div>
              <button
                onClick={handleResetPassword}
                disabled={loading}
                className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
              >
                {loading ? (
                  <CircularProgress size={20} className="text-white" />
                ) : (
                  "Verify"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
