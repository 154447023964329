import React, { useEffect, useState } from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";
import { CgCheck } from "react-icons/cg";
import Swal from "sweetalert2";

export default function HostelDetails() {
  const nav = useLocation();
  const data = nav.state;

  const goto = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [openRoom, setOpenRoom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [agreed, setAgreed] = useState(false)

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  const menuHandler2 = () => {
    setOpenRoom((p) => !p);
  };

  useEffect(() => {
    handleSearch(searchInput);
  }, [filteredResults, data]);

const handleAgreement = (target) =>{
  if (target.checked) {
    setAgreed(true)
  }else{
    setAgreed(false)
  }
}

  const handlePay = () => {
    if (agreed===true) {
      goto("/payment-page", { state: { id:selectedId } });
    } else {
      Swal.fire(
        "Tenancy Agreement",
        "You need to agree to the tennacy agreement to continue",
        "info"
      );
    }
  };


  return (
    <>
      <head>
        <title>Hostel Details</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%] text-white sm:mt-0 w-[100vw] h-[100vh] p-[10px] text-black overflow-auto no-scrollbar">
        <BackButton />
        <div className="h-[90vh] flex flex-col sm:gap-[20px] gap-[10px] pb-[80px] overflow-auto no-scrollbar items-center">
          <p className="font-bold sm:text-xl">{data.name}</p>
          <a
            className="w-[90%] rounded-[10px] border-[#113311] border-2 "
            href={data.imageUrl}
          >
            <img
              src={data.imageUrl}
              alt="Hostel Image"
              data-aos="zoom-in-up"
              data-aos-duration="3000"
            />
          </a>
          <p className="w-full sm:p-[10px] p-[5px]">
            <b>Location:</b> {data.address}
          </p>
          <p className="w-full sm:p-[10px] p-[5px]">
            <b>Cheapest Price GH¢:</b> {data.cheapestPrice}
          </p>
          {data.facilities ? (
            <p className="w-full sm:p-[10px] p-[5px] flex flex-col">
              <b>Available Facilities:</b>{" "}
              {data.facilities.map((item) => (
                <div className="flex w-full justify-between">
                  {item} <CgCheck />
                </div>
              ))}
            </p>
          ) : null}
          {data.tenancyAgreement ? (
            <div className="w-full text-[#ffd700]">
              <a href={data.tenancyAgreement}>
                Click to View Tenancy Agreement
              </a>
            </div>
          ) : null}
          {data.youtubeUrl ? (
            <div className="w-full text-[#ffd700]">
              <a href={data.youtubeUrl}>Click to Watch Hostel Video</a>
            </div>
          ) : null}
          <div className="w-full grid sm:grid-cols-3 grid-cols-2 sm:gap-[20px] gap-[10px] h-fit">
            {data.images.map((item) => (
              <a href={item}>
                <img
                  src={item}
                  alt="Hostel Image"
                  className="w-full rounded-[10px] border-[#113311] border-2 "
                />
              </a>
            ))}
          </div>
          <p className="w-full sm:p-[10px] p-[5px]">{data.description}</p>
          <p className="text-white font-bold">Tap to book a room</p>
          <div className="w-full grid sm:grid-cols-3 grid-cols-1 sm:gap-[20px] gap-[10px] h-fit">
            {data.rooms.map((item, index) => (
              <button
                onClick={() => {
                  setSelectedId(item.roomId);
                  setOpenRoom(true);
                }}
                className="w-full bg-[#ffd700] text-black rounded-[10px] flex flex-col gap-[5px] p-[10px]"
              >
                <div className="w-full flex justify-between">
                  <div className="text-xl font-bold">{item.name}</div>
                  <div className="text-white font-bold bg-black p-[5px] rounded-[5px] shadow-md shadow-black shadow-inner">
                    {item.price ? `Gh¢ ${item.price}` : "Price Undisclosed"}{" "}
                    {item.initialPrice ? (
                      <del className="text-gray-500 h-2 ml-[10px]">
                        GH¢ {item.initialPrice}
                      </del>
                    ) : null}
                  </div>
                </div>
                <div>{item.type}</div>
                <div>{item.floor} floor</div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <Sidebar />
      {openRoom && (
        <div
          className="absolute top-0 w-full h-full text-white  flex justify-center items-center p-[20px] bg-[#000000cc]"
          onClose={menuHandler2}
        >
          <div className="w-[80vw] h-fit bg-white rounded-[20px] p-[10px] flex flex-col  items-center  text-black">
            <div className="w-full flex flex-row-reverse">
              <IoCloseCircle
                color="#448844"
                size={40}
                onClick={menuHandler2}
                className="mb-[20px]"
              />
            </div>
            <div className="w-full h-fit overflow-auto no-scrollbar px-[20px]">
              <b className="text-[#114411] uppercase">Tenancy Agreement</b>
              <br />
              <br />
              <div className="w-full font-bold">
                <a href={data.tenancyAgreement}>
                  Click to read Tenancy Agreement
                </a>
              </div>
              <br />
              <br />
              <div className="flex gap-[10px]">
                <input type="checkbox" onChange={(e)=>handleAgreement(e.target)}/>
                Agree to T&Cs.
              </div>
            </div>
            <button onClick={handlePay} className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5">
              {loading ? (
                <CircularProgress size={20} className="text-white" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
