import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { CircularProgress } from "@mui/material";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios
        .post("https://molaapi.onrender.com/auth/login", { email, password })
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data));

          if (res.data.status === "ok") {
            setLoading(false);
            return navigate("/home");
          } else {
            setLoading(false);
            Swal.fire("Error", res.data.msg, "error");
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Error",
        "Something went wrong, please check your network connection!!",
        "error"
      );
    }
  };

  return (
    <div className="m-auto h-[100vh] w-[100vw] flex justify-center items-center">
      <head>
        <title>Mola App - Login Page</title>
      </head>
      
      <div className="sm:w-[50vw] w-[80vw] h-[full] border-2 border-[#448844] bg-[#ffffff99] rounded-md py-4">
        <img src="/mola-icon.png" alt="Mola Icon" className="w-[20%] m-auto" />
        <form
          action=""
          className=" w-[100%] flex flex-col justify-center items-center"
        >
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Email</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Password</label>
            <div className="relative ">
              <input
                type={passwordType}
                className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {passwordType === "password" ? (
                <HiEye
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword}
                />
              ) : (
                <HiEyeOff
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword}
                />
              )}
            </div>
          </div>
          <Link to="/register" className="text-[#113311]">
            Don't have an account? Register!
          </Link>
          <Link to="/forgot-password" className="text-[#113311] mt-[10px]">
            Forgot Password? Reset!
          </Link>
          <button
            onClick={handleLogin}
            disabled={loading}
            className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
