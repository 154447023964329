import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDBB9G1APcxKrVALF-aXFOWG1k901oS2JU",
    authDomain: "mola-bbab4.firebaseapp.com",
    databaseURL: "mola-bbab4.firebaseio.com",
    projectId: "mola-bbab4",
    storageBucket: "mola-bbab4.appspot.com",
    messagingSenderId: "381668188104",
    appId: "1:381668188104:web:6764a5cc8b699df94eeafd",
    measurementId: "G-7ZQ1FV1QXW"
  };
  
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
      console.log('FCM Token:', token);
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log('Foreground Message:', payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };