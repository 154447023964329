import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { FiSearch } from "react-icons/fi";
import useFetch from "../hooks/useFetch";
import { CircularProgress } from "@mui/material";
import HostelCard from "../components/hostel_card";
import { Link } from "react-router-dom";
import BackButton from "../components/back_button";

export default function OfflineHostels() {
  const { data, loading } = useFetch("https://molaapi.onrender.com/offline-hostels");

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return (item.name.toLowerCase().includes(searchInput.toLowerCase())||item.address.toLowerCase().includes(searchInput.toLowerCase()));
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };
  useEffect(() => {
    handleSearch(searchInput);
  }, [filteredResults, data]);


  return (
    <>
      <head>
        <title>Offline Hostels</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%]sm:mt-0 w-[100vw] h-[93vh] p-[10px] overflow-auto no-scrollbar">
        <div className="relative mb-[10px] pt-[50px]">
          <FiSearch color="green" className="absolute top-[14px] ml-[10px]" />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            type="search"
            className="pl-[30px] sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
            placeholder="Search hostel name"
          />
        </div>
        <BackButton />
        <div className="h-[90vh] grid sm:grid-cols-3 grid-cols-1 sm:gap-[20px] gap-[10px] pb-[30px] overflow-auto no-scrollbar">
          {loading ? (
            <div className="w-full h-full flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            filteredResults.map((items, index) => (
              <Link state={items} to={{ pathname: "/offline-hostel-details" }}>
                <HostelCard
                  index={index}
                  name={items.name}
                  img={items.imageUrl}
                  location={items.address}
                  cheapest={items.cheapestPrice}
                  roomTypes = {items.roomTypes}
                  facilities={items.facilities}
                  duration={items.duration}
                  youtubeUrl={items.youtubeUrl}
                  tenancyAgreement = {items.tenancyAgreement}
                />
              </Link>
            ))
          )}
        </div>
      </div>
      <Sidebar />
    </>
  );
}
