import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { FiSearch } from "react-icons/fi";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import BackButton from "../components/back_button";
import useFetch from "../hooks/useFetch";

export default function Apartments() {
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const {data, loading} = useFetch("https://molaapi.onrender.com/rooms");

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredApartments = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.hostel.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.type.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredApartments);
    } else {
      setFilteredResults(data);
    }
  };

  const borderColor = (index) => {
    if (index % 3 === 0) {
      return "bg-[#ffffff] border-[#ffffff] border-2 text-black hover:bg-white hover:text-black";
    } else if (index % 2 === 0) {
      return "bg-[#ffd700] border-[#ffd700] border-2 text-black hover:bg-white";
    } else {
      return "bg-[#448844] border-[#448844] border-2 text-white hover:bg-white hover:text-black";
    }
  };

  useEffect(() => {
    handleSearch(searchInput);
  }, [filteredResults, data]);

  return (
    <>
      <head>
        <title>Apartments</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%] sm:mt-0 w-[100vw] h-[100vh] p-[10px] overflow-auto no-scrollbar">
        <div className="relative mb-[10px] pt-[50px]">
          <FiSearch color="green" className="absolute top-[14px] ml-[10px]" />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            type="search"
            className="pl-[30px] sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
            placeholder="Search room name/hostel/type"
          />
        </div>
        <BackButton />
        <div className="h-[80vh] grid sm:grid-cols-3 grid-cols-1 sm:gap-[20px] gap-[10px] pb-[30px] overflow-auto no-scrollbar">
          {loading ? (
            <div className="w-full h-full flex justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            filteredResults.map((items) => (
              <Link state={items._id} to={{ pathname: "/payment-page" }}>
                <div
                  key={items._id}
                  className={`${borderColor(
                    items.index
                  )} w-full sm:h-fit h-fit p-[10px] sm:rounded-[20px] rounded-[10px] cursor-pointer shadow-[#] shadow-md`}
                >
                  <p className="font-bold sm:text-xl">{items.name}</p>
                  <p className="sm:p-[10px] p-[5px]">
                    <b>{items.hostel}</b>
                  </p>
                  <p className="sm:p-[10px] p-[5px]">
                    <b>Room Type:</b>
                     {items.type}
                  </p>
                  <p className="sm:p-[10px] p-[5px]">
                    <b>Loacted on:</b>
                     {items.floor}
                  </p>
                  <p className="sm:p-[10px] p-[5px]">
                    Room is {items.status} for booking
                  </p>
                  <p className="sm:p-[10px] p-[5px] w-fit bg-[#113311] shadow-md px-[10px] rounded-[5px] text-white shadow-black">
                    <b>Price GH¢:</b> {items.price}
                  </p>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
      <Sidebar />
    </>
  );
}
