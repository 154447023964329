import React, { useEffect, useState } from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";
import { Link, useLocation } from "react-router-dom";
import { CgCheck } from "react-icons/cg";

export default function OfflineHostelDetails() {
  const nav = useLocation();
  const data = nav.state;

  console.log(data);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  useEffect(() => {
    handleSearch(searchInput);
  }, [filteredResults, data]);

  return (
    <>
      <head>
        <title>Offline Hostels Details</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%] text-white sm:mt-0 w-[100vw] h-[100vh] p-[10px] text-black overflow-auto no-scrollbar">
        <BackButton />
        <div className="h-[90vh] flex flex-col sm:gap-[20px] gap-[10px] pb-[80px] overflow-auto no-scrollbar items-center">
          <p className="font-bold sm:text-xl">{data.name}</p>
          <img
            src={data.imageUrl}
            alt="Hostel Image"
            className="w-[90%] rounded-[10px] border-[#113311] border-2 "
          />
          <p className="w-full sm:p-[10px] p-[5px]">
            <b>Location:</b> {data.address}
          </p>
          {data.cheapestPrice ? (
            <p className="w-full sm:p-[10px] p-[5px]">
              <b>Cheapest Price GH¢:</b> {data.cheapestPrice}
            </p>
          ) : (
            "Price Undisclosed"
          )}
          <div className="w-full grid sm:grid-cols-3 grid-cols-2 sm:gap-[20px] gap-[10px] h-fit">
            {data.images.map((item) => (
              <img
                src={item}
                alt="Hostel Image"
                className="w-full rounded-[10px] border-[#113311] border-2 "
              />
            ))}
          </div>
          <p className="sm:p-[10px] p-[5px]">{data.description}</p>
          {data.facilities ? (
            <p className="w-full sm:p-[10px] p-[5px] flex flex-col">
              <b>Available Facilities:</b>{" "}
              {data.facilities.map((item) => (
                <div className="flex w-full justify-between">
                  {item} <CgCheck />
                </div>
              ))}
            </p>
          ) : null}
          {data.tenancyAgreement ? (
            <div className="w-full text-[#ffd700]">
              <a href={data.tenancyAgreement}>
                Click to View Tenancy Agreement
              </a>
            </div>
          ) : null}
          {data.youtubeUrl ? (
            <div className="w-full text-[#ffd700]">
              <a href={data.youtubeUrl}>Click to Watch Hostel Video</a>
            </div>
          ) : null}
          <div className="w-full grid sm:grid-cols-3 grid-cols-2 sm:gap-[20px] gap-[10px] h-fit">
            {data.images.map((item) => (
              <a href={item}>
                <img
                  src={item}
                  alt="Hostel Image"
                  className="w-full rounded-[10px] border-[#113311] border-2 "
                />
              </a>
            ))}
          </div>
          <p className="text-white font-bold text-center uppercase">
            Schedule a tour for this hostel with us to see the rooms and book
            the one that suit your taste.
          </p>
          <Link state={data.name} to="/tour-page">
            <button className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5  shadow-md">
              Schedule A Tour
            </button>
          </Link>
        </div>
      </div>
      <Sidebar />
    </>
  );
}
