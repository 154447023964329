import React from "react";
import { CgCheck } from "react-icons/cg";
import { FaHouse, FaHouseChimneyWindow } from "react-icons/fa6";
import { HiClock } from "react-icons/hi2";

export default function HostelCard(props) {
  return (
    <div className="bg-[#448844] border-[#448844] border-2 text-white hover:bg-white hover:text-black w-full h-fit p-[10px] sm:rounded-[20px] rounded-[10px] cursor-pointer shadow-md">
      <img
        src={props.img}
        alt="Hostel"
        className="w-full rounded-[10px] shadow-md"
      />
      <p className="w-full font-bold text-xl text-center ">{props.name}</p>
      <p className="w-full sm:p-[10px] p-[5px]">
        <b>Location:</b> {props.location}
      </p>
      <p className="w-full sm:p-[10px] p-[5px] w-fit bg-white shadow-md px-[10px] rounded-[5px] text-black">
        {props.cheapest?`Cheapest Price GH¢: ${props.cheapest}`:"Price Undisclosed"}
      </p>
      <p className="w-full flex gap-[10px] items-center py-[10px]">
        <HiClock size={20}/>
        <p className="font-bold text-[#ffd700]">Priced {props.duration}</p>
      </p>
      <p className="w-full sm:p-[10px] p-[5px] sm:visible invisible">
        {props.description}
      </p>
      {props.youtubeUrl ? (
            <div className="text-[#ffd700]">
              <a href={props.youtubeUrl}>Click to View Hostel Youtube Video</a>
            </div>
          ) : null}
      {props.tenancyAgreement ? (
            <div className="text-[#ffd700]">
              <a href={props.tenancyAgreement}>Click to View Tenancy Agreement</a>
            </div>
          ) : null}
      {props.roomTypes ? (
        <p className="w-full sm:p-[10px] p-[5px] flex flex-col">
          <b className="flex gap-[10px] items-center text-[#ffd700]"> Available rooms in this hostel <FaHouse/></b>{" "}
          {props.roomTypes.map((item) => (
            <div className="flex w-full justify-between">{item}</div>
          ))}
        </p>
      ) : null}
      {props.facilities ? (
        <p className="w-full sm:p-[10px] p-[5px] flex flex-col">
          <b className="flex gap-[10px] items-center text-[#ffd700]">Available Facilities <FaHouseChimneyWindow/></b>{" "}
          {props.facilities.map((item) => (
            <div className="flex w-full justify-between">
              {item} <CgCheck />
            </div>
          ))}
        </p>
      ) : null}
    </div>
  );
}
