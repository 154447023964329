import React from "react";
import Sidebar from "../components/Sidebar";
import BackButton from "../components/back_button";

export default function TenancyAgreement() {
  return (
    <>
      <head>
        <title>Tenancy Agreement</title>
      </head>
      <div className="sm:mt-0 w-[100vw] h-[100vh] p-[10px] flex flex-col text-black gap-[10px] overflow-auto no-scrollbar bg-white">
        <BackButton />
        <p className="mt-[50px]">
          <b className="text-[#114411] uppercase">Tenancy Agreement</b>
          <br />
          <br />
          <b>Disclaimer:</b> <br />
          Male student should not intentionally book a female student room in
          the case of 2 in a room,3 in a room and 4 in a room and vice versa.
          Anyone who doesn't adhere to this rules does it at his or her own risk
        </p>
        <p>
          <b>Terms and Conditions:</b> <br />
          Mola Estate Agency is not into the management of the various hostels
          on our platform.We host and sell the rooms of various hostels on our
          platform. Students should take notice of the following rules on our
          platform.
          <br />
          <br />
          <b>1.</b> Selection of hostel and room number is irreversible and
          money paid for a particular room is non refundable. Therefore students
          should check well before making payment for a room.
          <br />
          <br />
          <b>2.</b> After payment of the selected hostel fee, students should
          read the tenancy agreement well before signing. The signing of the
          tenancy agreement will be at the premises of the selected hostel. If a
          student doesn't agree to the tenancy agreement, a refund will be made
          to that particular student by the selected hostel management. If a
          student fails to read the tenancy agreement between him or her and the
          hostel manager,he or she does it at his or her own risk.
          <br />
          <br />
          <b>3.</b> Student must adhere to the various rules and regulations of
          the hostel he or her chooses. Mola Estate Agency will not be liable
          for any damages if a student disobeys the rules and regulations of the
          hostel he or she chooses.
          <br />
          <br />
          <b>4.</b> Apart from the each hostel security measures to safeguard
          students, students should also take personal security serious. If a
          student flout the selected hostel security measures, he or she does it
          at his or her own risk,Mola Estate Agency will not be liable.
        </p>
      </div>
      <Sidebar />
    </>
  );
}
