import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import HomePage from './pages/home';
import BookedRooms from './pages/booked_rooms';
import Payments from './pages/payments';
import Profile from './pages/profile';
import About from './pages/about';
import TenancyAgreement from './pages/tenancy';
import TermsNConditions from './pages/terms';
import Notifications from './pages/notifications';
import HostelDetails from './pages/hostel_details';
import Register from './pages/register';
import Hostels from './pages/hostels';
import ForgotPassword from './pages/forgot_password';
import OfflineHostels from './pages/offline_hostels';
import OfflineHostelDetails from './pages/offline_hostel_details';
import TourPage from './pages/tour_page';
import PaymentPage from './pages/payment_page';
import Apartments from './pages/apartments';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { setupNotifications } from './hooks/firebase.js';
import { toastNotification, sendNativeNotification } from './hooks/notificationHelpers.js';
import useVisibilityChange from './hooks/useVisibilityChange.js';


function App() {
  
  const isForeground = useVisibilityChange();

  useEffect(()=>{
    AOS.init();
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: message.title,
          description: message.body,
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: message.title,
          body: message.body,
        });
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path='/'/>
        <Route element={<Register />} path='/register'/>
        <Route element={<ForgotPassword />} path='/forgot-password'/>
        <Route element={<HomePage />} path='/home'/>
        <Route element={<Apartments />} path='/apartments'/>
        <Route element={<Hostels />} path='/hostels'/>
        <Route element={<PaymentPage />} path='/payment-page'/>
        <Route element={<OfflineHostels />} path='/offline-hostels'/>
        <Route element={<TourPage />} path='/tour-page'/>
        <Route element={<BookedRooms />} path='/booked'/>
        <Route element={<Payments />} path='/payments'/>
        <Route element={<Profile />} path='/profile'/>
        <Route element={<About />} path='/about'/>
        <Route element={<HostelDetails />} path='/details'/>
        <Route element={<OfflineHostelDetails />} path='/offline-hostel-details'/>
        <Route element={<Notifications />} path='/notifications'/>
        <Route element={<TenancyAgreement />} path='/tenancy'/>
        <Route element={<TermsNConditions />} path='/terms'/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
