import React, { useState } from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

export default function TourPage() {
  const user = JSON.parse(localStorage.getItem("user")).details;
  const nav = useLocation();
  const hostel = nav.state;
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSchedule = async () => {
    if (time === "" || date === "") {
      Swal.fire("Schedule", "Please select a date and time.", "error");
    } else {
      const nD = date.$d.toString().split(" ");
      const newDate = `${nD[1]} ${nD[2]} ${nD[3]}`;
      const nT = `${time.$H}:${time.$m}`;
      const data = { email:user.email, name:user.name, phone:user.phone, imageUrl:user.imageUrl, hostelName: hostel, date: newDate, time: nT };
      setLoading(true);

      try {
        await axios
          .post("https://molaapi.onrender.com/schedule", data)
          .then((res) => {
            if (res.data.status === "ok") {
              setLoading(false);
              Swal.fire("Schedule", res.data.msg, "success");
            } else {
              setLoading(false);
              Swal.fire("Schedule", res.data.msg, "error");
            }
          });
      } catch (error) {
        setLoading(false);
        Swal.fire(
          "Schedule",
          "Something went wrong, please check your network connection!!",
          "error"
        );
      }
    }
  };


  return (
    <>
      <head>
        <title>Schedule a Tour</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%] text-white sm:mt-0 w-[100vw] h-[93vh] p-[10px] flex flex-col text-black gap-[10px] overflow-auto no-scrollbar">
        <BackButton />
        <div className="flex flex-col gap-[20px] mt-[20vh]">
          <div className="font-bold mb-[20px]">
            NB: Please, our team is only available for tours on weekends. So
            please select a weekend day for your tour. Selecting a week day will
            be ignored and your booking will be overlooked.
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                label="Select Weekend Date"
                format="DD - MM - YYYY"
                className="w-full rounded-md bg-white"
                onChange={(val) => setDate(val)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Choose a Time"
                className="w-full rounded-md bg-white"
                onChange={(val) => setTime(val)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <button
            onClick={handleSchedule}
            disabled={loading}
            className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md shadow-md"
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Schedule"
            )}
          </button>
        </div>
      </div>
      <Sidebar />
    </>
  );
}
