import React from "react";
import { IoArrowBack } from "react-icons/io5";

export default function BackButton() {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div className="absolute fixed top-[10px] sm:invisible visible flex w-full">
      <button
        onClick={handleBack}
        className="sm:w-[100px] sm:h-[100px] h-[50px] w-[50px] bg-[#113311] rounded-full sm:p-[20px] p-[5px] flex justify-center items-center"
      >
        <IoArrowBack color="#ffd700" size={25} />
      </button>
    </div>
  );
}
