import React, { useEffect, useState } from "react";
import { MdCurrencyExchange } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import {
  FaBell,
  FaEllipsisVertical,
  FaFile,
  FaInfo,
  FaUserTie,
} from "react-icons/fa6";
import { SiHatenabookmark } from "react-icons/si";
import { LuFileCheck, LuLayoutDashboard, LuLogOut } from "react-icons/lu";
import { IoCloseCircle } from "react-icons/io5";
import { HiHomeModern, HiStar } from "react-icons/hi2";


export default function Sidebar() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const currentLocation = location.pathname;
  const currentcolor = (route) => {
    if (currentLocation === route) {
      return "bg-transparent sm:border-4  border-y-[#448844] border-2 text-white font-bold";
    }
  };

  const customStyles = {
    overlay: {
      backgroundColor: "#ffffff",
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuHandler = () => {
    setMenuOpen((p) => !p);
  };

  const user = JSON.parse(localStorage.getItem("user")).details;

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <div>
      {size.width < 768 ? (
        <div className="bg-black fixed bottom-0 flex flex-grow justify-between items-center mt-auto w-[100vw] h-fit p-[5px] ">
          {menuOpen && (
            <div>
              <Modal
                className="bg-gradient-to-b from-[#448844] to-black from-[50%] to-[90%]  absolute text-[#448844] ml-[50vw] w-[50vw] h-[100vh] px-[10px] flex gap-[20px] flex-col items-start overflow-auto no-scrollbar"
                open={true}
                onClose={menuHandler}
              >
                <Box sx={customStyles}>
                  <IoCloseCircle size={40} onClick={menuHandler} color="#ffd700"/>
                  <p className="flex flex-col justify-center items-center p-[10px] font-bold mb-[30px]">
                    <img
                      src={user.imageUrl}
                      alt="Profile"
                      className="w-[20vw] h-[20vw] rounded-full"
                    />{" "}
                    <p className="text-[#ffd700]">{user.name}</p>
                  </p>
                  <hr color="#0000ff" />
                  <div className="overflow-auto no-scrollbar w-full h-[90vh] flex flex-col gap-[10px] py-[10px] font-bold text-white">
                    <Link to="/about">About</Link>
                    <hr color="#0000ff" />
                    <Link to="https://play.google.com/store/apps/details?id=com.alpha.mola_app">Rate and Review</Link>
                    <hr color="#0000ff" />
                    <Link to="/terms">Terms and Conditions</Link>
                    <hr color="#0000ff" />
                    <Link
                      onClick={handleLogout}
                      to="/"
                      className="text-red-500"
                    >
                      Logout
                    </Link>
                    <hr color="#0000ff" />
                  </div>
                </Box>
              </Modal>
            </div>
          )}
          <Link
            to="/home"
            className={`${currentcolor("/home")}  rounded-full p-[10px]`}
          >
            <LuLayoutDashboard color="#448844" size={25} />
          </Link>

          <Link
            to="/booked"
            className={`${currentcolor("/booked")}  rounded-full p-[10px]`}
          >
            <SiHatenabookmark color="#448844" size={25} />
          </Link>

          <Link
            to="/payments"
            className={`${currentcolor("/payments")}  rounded-full p-[10px]`}
          >
            <MdCurrencyExchange color="#448844" size={30} />
          </Link>

          <Link
            to="/profile"
            className={`${currentcolor("/profile")}  rounded-full p-[10px]`}
          >
            <FaUserTie color="#448844" size={25} />
          </Link>

          {!menuOpen ? (
            <FaEllipsisVertical
              size={25}
              color="#ffffff"
              onClick={menuHandler}
              className="visible sm:invisible"
            />
          ) : (
            ""
          )}
          <div className="absolute right-[10px] bottom-[87vh] w-[50px] h-[50px] bg-[#ffd700]  rounded-full p-[10px] flex justify-center items-center shadow-md shadow-black">
            <Link to="/notifications">
              <FaBell color="black" size={25} />
            </Link>
          </div>
          <div className="absolute right-[10px] bottom-[10vh] w-fit h-fit bg-blue-500 text-white rounded-[10px] p-[10px] shadow-md shadow-green-800">
            <Link to="/offline-hostels" className="flex flex-col items-center">
              <HiHomeModern color="#ffffff" size={35} />
              <p className="text-[10px] text-white">Offline Hostels</p>
            </Link>
          </div>
        </div>
      ) : (
        <div className="bg-black fixed bottom-0 flex flex-grow justify-between items-center mt-auto w-[100vw] h-fit p-[5px] ">
          <div className="absolute right-[10px] bottom-[10vh] w-fit h-fit bg-blue-500 text-white rounded-[10px] p-[10px] shadow-md shadow-green-800">
            <Link to="/offline-hostels" className="flex flex-col items-center">
              <HiHomeModern color="#ffffff" size={35} />
              <p className="text-[10px] text-white">Offline Hostels</p>
            </Link>
          </div>
          <Link
            to="/home"
            className={`${currentcolor("/home")}  rounded-full p-[10px]`}
          >
            <LuLayoutDashboard color="#448844" size={25} />
          </Link>

          <Link
            to="/booked"
            className={`${currentcolor("/booked")}  rounded-full p-[10px]`}
          >
            <SiHatenabookmark color="#448844" size={25} />
          </Link>

          <Link
            to="/payments"
            className={`${currentcolor("/payments")}  rounded-full p-[10px]`}
          >
            <MdCurrencyExchange color="#448844" size={30} />
          </Link>
          <Link
            to="/notifications"
            className={`${currentcolor(
              "/notifications"
            )}  rounded-full p-[10px]`}
          >
            <FaBell color="#448844" size={30} />
          </Link>

          <Link
            to="/about"
            className={`${currentcolor("/about")}  rounded-full p-[10px]`}
          >
            <FaInfo color="#448844" size={30} />
          </Link>

          <Link
            to="https://play.google.com/store/apps/details?id=com.alpha.mola_app"
            className={`${currentcolor("/")}  rounded-full p-[10px]`}
          >
            <HiStar color="#448844" size={30} />
          </Link>

          <Link
            to="/terms"
            className={`${currentcolor("/terms")}  rounded-full p-[10px]`}
          >
            <FaFile color="#448844" size={30} />
          </Link>

          <Link
            to="/profile"
            className={`${currentcolor("/profile")}  rounded-full p-[10px]`}
          >
            <FaUserTie color="#448844" size={25} />
          </Link>

          <Link
          onClick={handleLogout}
            to="/"
            className={`${currentcolor("/")}  rounded-full p-[10px]`}
          >
            <LuLogOut color="#448844" size={25} />
          </Link>
        </div>
      )}
    </div>
  );
}
