import React from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";

export default function About() {
  return (
    <>
      <head>
        <title>About</title>
      </head>
      <div className="sm:mt-0 w-[100vw] h-[100vh] p-[10px] flex flex-col text-black gap-[10px] overflow-auto no-scrollbar bg-white">
        <BackButton />
        <p className="mt-[50px]">
          <b className="text-[#114411] uppercase">
            Welcome to Mola Estate Agency
          </b>
          <br /> <br />
          We're a cutting-edge agency dedicated to providing exceptional service
          and expertise, making your accommodation search effortless and
          stress-free. With years of experience and a team of local market
          experts, we've built a proven track record of success. Our commitment
          to excellence, integrity, and customer satisfaction sets us apart.
        </p>
        <p className="font-bold">Our app offers:</p>
        - Extensive property listings at your fingertips
        <br />- Real-time updates on new listings and price changes
        <br />- Direct connection with Hostel Managers for personalized support
        <br />- Exclusive market insights and news
        <p className="font-bold">Vision:</p>
        Mola aims to provide seamless online hostel booking experiences for
        tertiary students across Africa.
        <p className="font-bold">Mission:</p>
        We strive to make securing hostel accommodation easier for tertiary
        students. Whether you're searching for your ideal hostel or seeking to
        maximize your property's potential, we're passionate about helping you
        succeed. Explore our app today and discover the Mola Difference.
        
      </div>
      <Sidebar />
    </>
  );
}
