import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { PaystackConsumer } from "react-paystack";
import Swal from "sweetalert2";

export default function BookedRooms() {
  var publicKey = "pk_live_b7879d1fd22e6e9959743b94cbb44b92a045d3e5";
  const user = JSON.parse(localStorage.getItem("user")).details;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [arrears, setArrears] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const getBooedRooms = async () => {
    setLoading(true);
    await axios
      .post("https://molaapi.onrender.com/accepted-bookings/email", {
        email: user.email,
      })
      .then((value) => {
        setData(value.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBooedRooms();
    handleSearch(searchInput);
  }, [filteredResults]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.room.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  const componentProps = {
    email: user.email,
    amount: parseInt(arrears) * 100,
    currency: "GHS",
    metadata: {
      name: user.name,
      number: user.phone,
      custom_fields: [
        { value: data.hostel, display_name: "Name of Hostel" },
        { value: data.room, display_name: "Room Number" },
        { value: user.name, display_name: "Name of Person" },
        { value: user.email, display_name: "Email" },
      ],
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      var arrears = 0;
      var myData = {
        arrears: arrears,
      };
      try {
        axios
          .put(`https://molaapi.onrender.com/accepted-bookings/${id}`, myData)
          .then((res) => {
            if (res.data.status === "ok") {
              Swal.fire("Booking", "Arrears Paid Sucessfully", "success");
            } else {
              Swal.fire("Booking", "Error in saving arrears paid", "error");
            }
          });
      } catch (error) {
        Swal.fire("Booking", "NETWORK ERROR!!", "error");
      }
    },
    onClose: () => {
      Swal.fire(
        "Payment",
        "You have cancelled the payment of your arrears. Please pay your arrears to avoid eviction.",
        "info"
      );
    },
  };
  return (
    <>
      <head>
        <title>Booked Rooms</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[50%] w-[100vw] h-[100vh] p-[10px] overflow-auto no-scrollbar">
        <div className="relative mb-[10px]">
          <FiSearch color="green" className="absolute top-[14px] ml-[10px]" />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            type="search"
            name=""
            className="pl-[30px] sm:w-[50vw] w-full bg-white border-[2px] border-[#0047ab] placeholder-green-800 text-green-800 h-[45px] p-[10px] rounded-md"
            placeholder="Search hostel name / room"
          />
        </div>
        <div className="h-[90vh] grid sm:grid-cols-2 grid-cols-1 gap-[10px] sm:pb-[30px] overflow-auto no-scrollbar">
          {loading ? (
            <CircularProgress size={20} className="text-white" />
          ) : filteredResults.length < 1 ? (
            <div className="w-full h-full flex justify-center items-center text-white">
              No Bookings Yet
            </div>
          ) : (
            filteredResults.map((items) => (
              <div className="h-fit flex flex-col gap-[10px] bg-white rounded-[5px] shadow-md p-[10px] hover:bg-[#00000033] hover:text-white">
                <p>{items.room}</p>
                <p className="font-bold text-[#113311] hover:text-white">
                  {items.hostel}{" "}
                </p>
                <p>
                  <b>Paid:</b> {items.amount_paid}
                </p>
                <p>
                  <b>Arrears:</b> {items.arrears}
                </p>
                {parseInt(items.arrears) > 0 ? (
                  <div className="w-full flex justify-end">
                    <PaystackConsumer {...componentProps}>
                      {({ initializePayment }) => (
                        <button
                          onClick={() => {
                            setId(items._id);
                            setArrears(parseInt(items.arrears));
                            initializePayment();
                          }}
                          className="w-fit text-white px-6 py-2  hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md "
                        >
                          Pay Arrears
                        </button>
                      )}
                    </PaystackConsumer>
                  </div>
                ) : null}
              </div>
            ))
          )}
        </div>
      </div>
      <Sidebar />
    </>
  );
}
