import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IoCloseCircle } from "react-icons/io5";

export default function Register() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [imageId, setImageId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [Cpassword, setCPassword] = useState("");
  const [gender, setGender] = useState("Male");
  const [phone, setPhone] = useState("");
  const [level, setLevel] = useState("");
  const [program, setProgram] = useState("");
  const [token, setToken] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const edit = useRef();
  const [image, setImage] = useState("");

  const previewFile = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
    };
  };

  // const handleImage = async (e) => {
  //   const file = e.target.files[0];
  //   ;
  // };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  const menuHandler = () => {
    setOpenOTP((p) => !p);
  };

  const handleEmailToken = async () => {
    if (email === null || email === "") {
      Swal.fire(
        "Email Verfication",
        "No email provided! Please enter an email to verify!!",
        "error"
      );
    } else {
      setLoading(true);
      try {
        await axios
          .post("https://molaapi.onrender.com/confirmation", { email: email })
          .then((res) => {
            if (res.data.status === "ok") {
              setLoading(false);
              Swal.fire("Email Verfication", res.data.msg, "success");
              menuHandler();
            } else {
              setLoading(false);
              Swal.fire(
                "Email Verfication",
                "Error Sending Token, Please try again!!",
                "error"
              );
            }
          });
      } catch (error) {
        setLoading(false);
        Swal.fire(
          "Email Verfication",
          "Error Verifying Email, Please try again!!",
          "error"
        );
      }
    }
  };
  const handleEmailVerifcation = async () => {
    setLoading(true);
    try {
      await axios
        .get(`https://molaapi.onrender.com/confirmation/${email}/${token}`)
        .then((res) => {
          if (res.data.status === "ok") {
            setLoading(false);
            Swal.fire("Email Verfication", res.data.msg, "success");
            menuHandler();
            setVerified(true);
          } else {
            setLoading(false);
            Swal.fire(
              "Email Verfication",
              "Error Sending Token, Please try again!!",
              "error"
            );
            menuHandler();
          }
        });
    } catch (error) {
      setLoading(false);
      Swal.fire(
        "Email Verfication",
        "Error Verifying Email, Please try again!!",
        "error"
      );
      menuHandler();
    }
  };

  const handleRegister = async () => {
    setLoading(true);
    if (
      email === "" ||
      name === "" ||
      phone === "" ||
      gender === "" ||
      program === "" ||
      level === ""
    ) {
      setLoading(false);
      Swal.fire("Registration", "Please all fields are required!", "error");
    } 
    // else if (image === null || image === "") {
    //   setLoading(false);
    //   Swal.fire(
    //     "Registration",
    //     "Please select a profile picture to continue!",
    //     "error"
    //   );
    // }
     else if (verified === false) {
      setLoading(false);
      Swal.fire(
        "Registration",
        "Please verify your email to continue!",
        "error"
      );
    } else if (password !== Cpassword) {
      setLoading(false);
      Swal.fire("Registration", "Passwords don't match!", "error");
    } else {
      try {
        // await axios
        //   .post(("https://molaapi.onrender.com/users/upload", { image: image }))
        //   .then((res) => {
        //     if (res.data.status === "ok") {
        //       setImageId(res.data.data.imageId);
        //       setImageUrl(res.data.data.imageUrl);
        //     }
        //   });
        await axios
          .post("https://molaapi.onrender.com/auth/register", {
            name,
            email,
            gender,
            password,
            imageId,
            imageUrl,
            phone,
            program,
            level,
          })
          .then((res) => {
            if (res.data.status === "ok") {
              setLoading(false);
              Swal.fire(
                "Registration",
                "Registration Successful. Login to access your account!",
                "success"
              );
              return navigate("/");
            } else {
              setLoading(false);
              Swal.fire("Error", res.data.msg);
            }
          });
      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <div className="m-auto h-[100vh] w-[100vw] flex justify-center items-center">
      <head>
        <title>Mola App - Registration Page</title>
      </head>
      <div className="sm:w-[50vw] w-[80vw] h-[100vh] rounded-md  flex justify-center items-center flex-col">
        <img src="/mola-icon.png" alt="Mola Icon" className="w-[20%] m-auto" />
        <form
          action=""
          className=" bg-[#ffffff99] w-[100%] h-[80vh] flex flex-col items-center overflow-auto no-scrollbar py-[20px] rounded-[10px]"
        >
          <div className="relative flex flex-col mb-[30px] gap-[10px] justify-center items-center">
            {image === null || image === "" ? (
              <img
                src=""
                className="w-[100px] h-[100px] rounded-full border-2 border-[#3bb143] bg-[#113311]"
              />
            ) : (
              <img
                src={image}
                className="w-[100px] h-[100px] rounded-full border-2 border-[#3bb143] bg-[#113311]"
              />
            )}
            <div className="flex gap-[10px] cursor-pointer">
              <div
                onClick={(e) => edit.current.click()}
                className="absolute top-[70px] right-0 bg-white p-[10px] rounded-full"
              >
                <FiEdit2 color="green" />
              </div>
              <input
                onClick={async(e)=>{await previewFile(e.target.file[0])}}
                ref={edit}
                type="file"
                name=""
                className="hidden"
              />
            </div>
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Full Name</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Email</label>
            <input
              type="email"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          {loading ? (
            <CircularProgress size={20} className="text-white" />
          ) : verified===true?<p>Verified</p> :(
            <Link onClick={handleEmailToken} className="text-[#113311]">
              Verify Email
            </Link>
          )}
          <div className="w-full p-4 flex flex-col">
          <label className="font-bold sm:text-xl">Gender</label>
          <select onChange={(e)=>setGender(e.target.value)}
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md">
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Phone</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Program</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setProgram(e.target.value);
              }}
            />
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Level</label>
            <input
              type="text"
              className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
              onChange={(e) => {
                setLevel(e.target.value);
              }}
            />
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Password</label>
            <div className="relative ">
              <input
                type={passwordType}
                className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {passwordType === "password" ? (
                <HiEye
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword}
                />
              ) : (
                <HiEyeOff
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword}
                />
              )}
            </div>
          </div>
          <div className="w-full p-4 flex flex-col">
            <label className="font-bold sm:text-xl">Confirm Password</label>
            <div className="relative ">
              <input
                type={passwordType2}
                className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md"
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
              />
              {passwordType2 === "password" ? (
                <HiEye
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword2}
                />
              ) : (
                <HiEyeOff
                  color="#3bb143"
                  className="absolute text-black right-[10px] top-[15px] bottom-[10px]"
                  onClick={togglePassword2}
                />
              )}
            </div>
          </div>
          <Link to="/" className="text-[#113311]">
            Already have an account? Login!
          </Link>
          <button
            onClick={handleRegister}
            disabled={loading}
            className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Register"
            )}
          </button>
        </form>
        {openOTP && (
          <div
            className="absolute w-full h-full text-white  flex justify-center items-center bg-[#000000aa]"
            onClose={menuHandler}
          >
            <div className="bg-white rounded-[20px] p-[10px] flex flex-col justify-center items-center">
              <div className="w-full flex flex-row-reverse">
                <IoCloseCircle
                  color="#448844"
                  size={30}
                  onClick={menuHandler}
                  className="mb-[20px]"
                />
              </div>
              <p className="text-black uppercase">
                Please enter <b>OTP</b>
              </p>
              <div className="w-full p-4 flex flex-col">
                <label className="font-bold sm:text-xl">Enetre OTP</label>
                <input
                  type="text"
                  className="w-full border-2 border-[#3bb143] px-5 py-2 rounded-md text-black"
                  onChange={(e) => {
                    setToken(e.target.value);
                  }}
                />
              </div>
              <button
                onClick={handleEmailVerifcation}
                disabled={loading}
                className="text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
              >
                {loading ? (
                  <CircularProgress size={20} className="text-white" />
                ) : (
                  "Verify"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
