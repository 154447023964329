import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function HomePage() {
  const user = JSON.parse(localStorage.getItem("user")).details;
  const { data, loading } = useFetch("https://molaapi.onrender.com/hostels");
  const { data: ads, loading: load } = useFetch(
    "https://molaapi.onrender.com/adverts"
  );
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleSearch = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data.filter((item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };
  useEffect(() => {
    handleSearch(searchInput);
  }, [filteredResults, data]);

  return (
    <>
      <head>
        <title>Dashboard</title>
      </head>
      <div>
        <div className="bg-gradient-to-b from-black to-[#448844] from-[10%] to-[65%] w-[100vw] h-[100vh] flex flex-col gap-[20px]  items-center overflow-auto no-scrollbar">
          <div className="bg-[#448844] w-full text-white uppercase font-bold flex items-center gap-[10px] justify-center p-[10px] text-[20px]">
            
               Welcome to Mola App
            
          </div>
          <div className="w-full flex flex-col justify-center items-center h-fit">
            <Carousel
              data-aos="zoom-out"
              data-aos-duration="3000"
              showArrows={true}
              showIndicators={true}
              infiniteLoop={true}
              swipeable={true}
              autoPlay={true}
              showThumbs={false}
              className=" w-[100vw] sm:w-[40%] h-fit flex items-center justify-center rounded-md"
            >
              {ads.map((item) => (
                <div
                  className="w-[50%] h-[40vh] ml-[25%]  rounded-md flex items-center justify-center p-[5px] bg-[#448844]"
                  key={item._id}
                >
                  <div className="flex justify-center items-center">
                    <img src={item.adUrl} alt="slides" className="full" />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>

          <div className="w-full flex sm:grid grid-cols-3 px-[20px] items-center gap-[30px] h-fit overflow-auto no-scrollbar">
            <Link to="/hostels">
              <div
                data-aos="fade-left"
                data-aos-duration="3000"
                className=" relative sm:w-full w-[85vw] flex flex-col justify-center items-center bg-[#ffffff] rounded-[10px] h-[32vh] shadow-md shadow-black"
              >
                <img
                  src="/hostel.jpeg"
                  alt="Hostels"
                  className="w-full h-full rounded-[5px]"
                />
                <p className="absolute bottom-0 font-bold sm:text-[18px] bg-[#ffffffaa] w-full text-center text-black py-[5px] rounded-[5px]">
                  Hostels
                </p>
              </div>
            </Link>
            <Link
              to=""
              onClick={() =>
                Swal.fire("Apartments", "Upcoming!! Anticipate", "info")
              }
            >
              <div
                data-aos="fade-left"
                data-aos-duration="3000"
                className=" relative sm:w-full w-[85vw] flex flex-col justify-center items-center bg-white rounded-[10px] h-[32vh] shadow-md shadow-black"
              >
                <img
                  src="/apartments.jpeg"
                  alt="Apartments"
                  className="w-full h-full rounded-[5px]"
                />
                <p className="absolute bottom-0 font-bold sm:text-[18px] bg-[#ffffffaa] w-full text-center text-black py-[5px] rounded-[5px]">
                  Appartments
                </p>
              </div>
            </Link>
            <Link
              to=""
              onClick={() =>
                Swal.fire("Delivery Service", "Upcoming!! Anticipate", "info")
              }
            >
              <div
                data-aos="fade-left"
                data-aos-duration="3000"
                className=" relative sm:w-full w-[85vw] flex flex-col justify-center items-center bg-white rounded-[10px] h-[32vh] shadow-md shadow-black"
              >
                <img
                  src="/delivery.jpeg"
                  alt="Deleivery Service"
                  className="w-full h-full rounded-[5px]"
                />
                <p className="absolute bottom-0 font-bold sm:text-[18px] bg-[#ffffffaa] w-full text-center text-black py-[5px] rounded-[5px]">
                  Delivery Service
                </p>
              </div>
            </Link>
          </div>
        </div>
        <Sidebar />
      </div>
    </>
  );
}
