import React from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";

export default function TermsNConditions() {
  return (
    <>
      <head>
        <title>Terms and Conditions</title>
      </head>
      <div className="sm:mt-0 w-[100vw] h-[100vh]  p-[10px] flex flex-col text-black gap-[10px] overflow-auto no-scrollbar bg-white">
        <BackButton />
        <p className="mt-[50px]">
          <b className="text-[#114411] uppercase">Terms and Conditions</b>
          <br />
          <br />
          <b>General Terms</b>
          <br />
          By accessing and booking room with Mola, you confirm that you are in
          agreement with and bound by the terms of service contained in the
          Terms & Conditions outlined below. These terms apply to the entire
          platform and any email or other type of communication between you and
          Mola. Mola Estate Agency is not into the management of the various
          hostels on our platform. We host hostel and allow bookings of the
          rooms of various hostels on our platform.
          <br />
          <br />
          <b>License</b>
          <br />
          Mola grants you a limited license to download, install and use our
          service strictly in accordance with the terms of this Agreement. You
          are agreeing to be bound by these Terms & Conditions if only you use
          our service. In these Terms & Conditions, refers both to you as an
          individual and to the entity you represent. If you violate any of
          these Terms & Conditions, we reserve the right to cancel your account
          or block access to your account without notice.
          <br />
          <br />
          <b>Entire Agreement</b>
          <br />
          The Agreement constitutes the entire agreement between you and us
          regarding your use of the service. Mola charges 4% commission on
          hostel fee which is already included in the price of room on our
          platform. This charge is not refundable under any circumstance. Also,
          money paid to book is non-refundable only on exceptional cases.
          Students/users are to read the tenancy agreement of selected hostel
          before accepting to book a room. Upon proceeding to book a room means
          you accept the terms regarding the particular hostel. Mola is not
          liable for any consequences arising from failure to read Tenancy
          agreement before making a booking.
          <br />
          <br />
          <b>Typographical Errors</b>
          <br />
          Selection of hostel and room number is irreversible and money paid for
          a particular room is nonrefundable. Therefore, students should check
          well before making payment for a room. In the event where there is
          typographical error like; price of a room is listed incorrectly or
          with incorrect information, or error of any sort, Mola shall have the
          right to refuse or cancel any such booking whether or not the bookings
          has been confirmed and your credit card charged. If your credit card
          has already been charged for the booking and your booking is canceled,
          we shall immediately issue a credit to your credit card account or
          other payment account in the amount of the charge.
          <br />
          <br />
          <b>Your Suggestions</b>
          <br />
          Any feedback, comments, ideas, improvements or suggestions
          (collectively, Suggestions) provided by you to us with respect to the
          service shall remain the sole and exclusive property of us. We shall
          be free to use, copy, modify, publish, or redistribute the Suggestions
          for any purpose and in any way without any credit or any compensation
          to you.
          <br />
          <br />
          <b>Updates to Our service</b>
          <br />
          We may from time to time provide enhancements or improvements to the
          features/ functionality of the service, which may include patches, bug
          fixes, updates, upgrades and other modifications. Updates may modify
          or delete certain features and/or functionalities of the service. You
          agree that we have obligation to
          <br />
          (i) provide any Updates, or
          <br />
          (ii) continue to provide or enable any particular features and
          functionalities of the service to you. You further agree that all
          Updates will be deemed to constitute an integral part of the service.
          <br />
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Then, if you continue to use the Service, you will be bound
          by the updated Terms.
          <br />
          <br />
          <b>Disclaimer:</b>
          <br />
          Our Service and its contents are provided as available without any
          warranty or representations of any kind, whether express or implied.
          We are just agency and not into the internal management of the hostel
          facility; as such, we offer no editorial control over such content and
          makes no warranty or representation as to the accuracy of any
          information, content, or service provided through or accessible via
          our Service. Price and available information is subject to change
          without notice. Without limiting the foregoing, we do not warrant that
          our Service will be uninterrupted, uncorrupted, timely, or error-free.
          Student must adhere to the various rules and regulations of the hostel
          he/she chooses. Mola will not be liable for any damages if a student
          disobeys the rules and regulations of the hostel he or she chooses.
          Apart from each hostel security measures to safeguard students,
          students should also take personal security seriously. If a student
          flouts the selected hostel security measures, he/she does it at his or
          her own risk, Mola will not be liable.
          <br />
          <br />
          <b>Contact Us:</b>
          <br />
          • Via Phone Number: 0508939273
          <br />• Via Email: mola2022estateagency@gmail.com
          <br/>
        </p>
      </div>
      <Sidebar />
    </>
  );
}
