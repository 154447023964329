import React, { useEffect, useState } from "react";
import BackButton from "../components/back_button";
import Sidebar from "../components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { PaystackConsumer } from "react-paystack";

export default function PaymentPage() {
  const navigate = useNavigate();
  const nav = useLocation();
  const roomId = nav.state.id;
  const user = JSON.parse(localStorage.getItem("user")).details;
  var email = user.email;
  var publicKey = "pk_live_b7879d1fd22e6e9959743b94cbb44b92a045d3e5";
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [room, setRoom] = useState("");
  const getRoom = async () => {
    setLoading(true);
    try {
      await axios
        .get(`https://molaapi.onrender.com/rooms/${roomId}`)
        .then((res) => {
          setRoom(res.data.data);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      Swal.fire("Room", "Network Error, Please refresh the page!!", "error");
    }
  };

  useEffect(() => {
    getRoom();
  }, []);

  const amt = amount;

  const onPaySuccess = () =>{
    var arrears = parseInt(room.price) - parseInt(amt);
      var myData = {
        name: user.name,
        imageUrl: user.imageUrl,
        email: user.email,
        gender: user.gender,
        phone: user.phone,
        hostel: room.hostel,
        room_type: room.type,
        room: room.name,
        program: user.program,
        level: user.level,
        roomId: room._id,
        amount_paid: amount,
        arrears: arrears,
      };
      console.log(myData)
      try {
        axios
          .post("https://molaapi.onrender.com/accepted-bookings", myData)
          .then((res) => {
            console.log(res)
            if (res.data.status === "ok") {
              Swal.fire("Booking", res.data.msg, "success");
              
              return navigate("/home");
            } else {
              Swal.fire("Booking", res.data.msg, "error");
            }
          });
      } catch (error) {
        Swal.fire("Booking", "NETWORK ERROR!!", "error");
      }
  }

  const componentProps = {
    email: email,
    amount: amt * 100,
    currency: "GHS",
    metadata: {
      name: user.name,
      number: user.phone,
      custom_fields: [
        { value: room.hostel, display_name: "Name of Hostel" },
        { value: room.name, display_name: "Room Number" },
        { value: user.name, display_name: "Name of Person" },
        { value: user.email, display_name: "Email" },
      ],
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => onPaySuccess(),
    onClose: () => {
      Swal.fire(
        "Payment",
        "You have opted out of payment for this room. Please try booking another room of your choice!",
        "info"
      );
      window.history.back();
    },
  };
console.log(room.people)
  return (
    <>
      <head>
        <title>Payment Mode Page</title>
      </head>
      <div className="bg-gradient-to-b from-black to-[#448844] from-[30%] to-[65%] text-white sm:mt-0 w-[100vw] h-[100vh] p-[10px] text-black overflow-auto no-scrollbar">
        <BackButton />
        <div className="h-[90vh] flex flex-col sm:gap-[20px] gap-[10px] pb-[80px] overflow-auto no-scrollbar items-center">
          <div className="sm:pt-0 pt-[50px] flex flex-col sm:gap-[30px] gap-[10px]">
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : !room ? (
              <div className="bg-[#ffd700] text-black p-[10px] flex justify-center items-center">
                Room Not Available
              </div>
            ) : (
              <div className="bg-[#ffd700] text-black p-[10px]">
                <p>
                  You are about to book <b>{room.name}</b> in{" "}
                  <b>{room.hostel}</b>. It is a <b>{room.type}</b> room and
                  comes at a cost of <b>GH¢ {room.price}</b> per year. It is
                  located on <b>{room.floor}</b> floor of the hostel.
                </p>
                <br/>
                <p className="font-bold">Your Room Mates</p>
                {room.people ? (
                  <p>
                    <div>
                      {room.people.map((person) => (
                        <div className="flex gap-[10px] items-center justify-center">
                          <img src={person.imageUrl} alt="Profile" className="w-[100px] h-[100px]"/>
                          <p>
                            <p>{person.name}</p>
                            <p>{person.gender}</p>
                          </p>
                        </div>
                      ))}
                    </div>
                  </p>
                ) : (
                  <p>No room mates available</p>
                )}
                <br/>
                <b>Please do not book a room with the opposite gender!!</b>
              </div>
            )}
            {room.status === "unavailble" ? (
              <div className="text-center uppercase">
                Sorry room is full or unavailble, Please book another room!
              </div>
            ) : (
              <div className="flex flex-col">
                <p className="text-white font-bold text-xl uppercase text-center">
                  Please note once you book a room, this process cannot be
                  reversed.{" "}
                </p>
                <p className="text-white font-bold text-center mt-[20px]">
                  Enter amount to pay.{" "}
                </p>
                <div className="w-full p-4 flex flex-col">
                  <label className="font-bold sm:text-xl">Amount</label>
                  <input
                    type="text"
                    className="w-full text-black border-2 border-[#3bb143] px-5 py-2 rounded-md"
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full flex justify-center">
                  <PaystackConsumer {...componentProps}>
                    {({ initializePayment }) => (
                      <button
                        onClick={() => {
                          let red = room.price / 2;
                          if (amount < red) {
                            Swal.fire(
                              "Payment",
                              "You can not pay less than half of the amount!!!",
                              "error"
                            );
                          } else {
                            initializePayment();
                          }
                        }}
                        className="w-fit text-white px-6 py-2 mt-12 hover:bg-[#3bb143aa] bg-[#3bb143] border-2 rounded-md mr-5"
                      >
                        Pay
                      </button>
                    )}
                  </PaystackConsumer>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  );
}
